import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { gutter, screenSizes } from '../theme'

const Content = styled.div`
  padding: 0px calc(${gutter.mobile}px + 10px);

  * {
    user-select: auto;
  }

  @media (min-width: ${screenSizes.tablet}px) {
    padding: 0px calc(${gutter.tablet}px + 100px);
  }

  @media (min-width: ${screenSizes.desktop}px) {
    padding: 235px calc(${gutter.desktop}px + 120px);
  }

  @media (min-width: ${screenSizes.desktopLarge}px) {
    padding: 235px calc(${gutter.desktopLarge}px + 110px);
  }

  h1 {
    font-size: 6vw;
  }
`

const PaginatedNumber = styled.span`
  &,
  a {
  }
  &:after {
    content: '\00a0';
  }
`

const PaginationContainer = styled.div`
  padding: 20px 0px 40px;
`

const PostContainer = styled.div`
  margin: 0px 0px 20px 0px;
  a,
  p,
  h1,
  span {
  }
  h2 {
    font-size: 16pt;
    margin-bottom: 4px;
  }
  h3 {
    margin-bottom: 0;
  }
  .author {
    opacity: 0.5;
  }
`

const Tag = styled.span`
  font-size: 9pt;
  opacity: 0.4;
  border-width: 1px;
  border-color: white;
  border-style: solid;
  border-radius: 10px;
  margin: 10px 10px 0 0;
  display: inline-block;
  a {
    padding: 0px 10px;
    display: block;
  }
`

const PostLink = ({ post }) => (
  <PostContainer>
    <Link to={post.fields.slug}>
      <h2>{post.frontmatter.title}</h2>
      <h3>
        <span>{post.frontmatter.date}</span>
        <span> - {post.timeToRead} min read</span>
      </h3>
    </Link>
    <div className="tags">
      {post.frontmatter.tags.split(',').map(tag => (
        <Tag key={tag}>
          <Link to={`blog/${tag}`}>{tag}</Link>
        </Tag>
      ))}
    </div>
    <span className="author">{post.frontmatter.author.id}</span>
  </PostContainer>
)
PostLink.propTypes = {
  post: PropTypes.object.isRequired,
}

const Pagination = ({ pageContext }) => {
  let slug = `blog/`
  if (pageContext.tag) {
    slug = `blog/${pageContext.tag}/`
  }
  return (
    pageContext.numPages > 1 && (
      <PaginationContainer>
        <PaginatedNumber current={true}>/</PaginatedNumber>
        {Array.from({ length: pageContext.numPages }).map((_, i) => (
          <PaginatedNumber key={i.toString()} current={i === pageContext.skip}>
            <Link to={i === 0 ? slug : `${slug}${i + 1}`}>{i + 1}</Link>
          </PaginatedNumber>
        ))}
      </PaginationContainer>
    )
  )
}

const BlogIndexPage = ({ data, pageContext }) => {
  const { edges } = data.allMarkdownRemark

  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <Layout headerTitle="Aweh">
      <SEO title="Blog" keywords={[`Teamgeek`, `Blog`]} />
      <Content>
        <h1>BLOG</h1>
        {Posts}
        <Pagination pageContext={pageContext} />
      </Content>
    </Layout>
  )
}
BlogIndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default BlogIndexPage

export const pageQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!, $tagRegex: String) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: $limit
      skip: $skip
      filter: { frontmatter: { tags: { regex: $tagRegex } } }
    ) {
      edges {
        node {
          id
          html
          timeToRead
          excerpt(pruneLength: 250)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            author {
              id
              bio
              twitter
            }
          }
        }
      }
    }
  }
`
